import {ErrorInfo, memo} from 'react';
import {Card, CardContent, CardHeader, Container} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import {ComponentWithoutChildren, PropsWithoutChildren} from '../react/types';
import {Translation} from '../i18n/Translation';

export interface ErrorProps {
  context?: string;
  error: Error;
  errorInfo?: ErrorInfo;
}

type ErrorCardProps = PropsWithoutChildren<ErrorProps>;

const ErrorCardComponent: ComponentWithoutChildren<ErrorCardProps> = ({context, error, errorInfo}: ErrorCardProps) => {
  return (
    <Container>
      <Card>
        <Translation keys="common:errorCard.title">
          {(somethingWentWrong) => (
            <CardHeader
              avatar={<ErrorIcon color="error" />}
              slotProps={{subheader: {fontFamily: 'monospace'}}}
              subheader={error.toString()}
              sx={{pb: 0}}
              title={`${somethingWentWrong}${context ? `: ${context}` : ''}`}
            />
          )}
        </Translation>
        <CardContent sx={{fontFamily: 'monospace', pt: 0}}>
          <pre>{errorInfo?.componentStack}</pre>
        </CardContent>
      </Card>
    </Container>
  );
};

export const ErrorCard = memo(ErrorCardComponent);
